.privacy__main {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 72px 243px 125px 243px;
  gap: 100px;
}

.privacy-top-main {
  padding: 60px 54px 0px 65px;
  display: flex;
  gap: 38px;
  overflow-x: hidden;
}

.privacy-main____top {
  display: flex;
  flex-direction: column;
}

.privacy-bold-website-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin: 0px !important;
  text-decoration: underline;
  text-decoration-color: #fff;
}

.evox-heading-text-main {
  font-family: PoppinsBold;
  font-size: 86px !important;
  font-weight: 700 !important;
  line-height: 82px !important;
  text-align: left;
  margin: 0px !important;
  color: #fff;
}

.privacy-evox-align-text {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.effective-div-for-small-screen {
  display: none;
}

.effective-date-text-main {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: right;
  margin: 0px !important;
  color: #fff;
}




.privacy-top-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-top: 74px;
  padding-bottom: 96px;
}




.privacy-main {
  display: flex;
  flex-direction: column;
  gap: 80px;

}

.effective-date-paragraph {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: right;

  margin: 0px !important;
  color: #fff;
}

.effective-date-paragraph-span {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: right;
  margin: 0px !important;
  color: #fff;
}

.evox-text-main-heading {
  font-family: PoppinsBold;
  font-size: 86px;
  font-weight: 700;
  line-height: 82px;
  text-align: left;
  color: #fff;
  margin: 0px !important;
  text-transform: uppercase;
}

.paragraph-of-main-text {
  margin: 0px !important;
  padding-left: 283px;
  padding-top: 57px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #fff;


}
.Privacy__main-right {
  
  background-image: url("/public//Images/Privacy/PrivacyBackground.png");
  background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    padding: 46px 75px 0px 80px;
    position: relative;
    border-radius: 50px;
}
@media (min-width: 1700px) {
  .paragraph-of-main-text{
    padding-left: 340px;
  }
}
 /* @media (min-width: 1441px) {
  .Privacy__main-right {
  


  background-image: url("/public//Images/Privacy/privacylarge.svg") !important;
  
  background-size: cover !important;
  background-repeat: no-repeat !important;
  width: 100% !important;
  padding: 46px 75px 0px 80px !important;
  position: relative !important;
  border-radius: 50px !important;
}

} */

.website-evox-bold-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #fff;
  margin: 0px !important;
  text-decoration: underline;
  text-decoration-color: #fff;
  ;
}

.evox-text-main-heading2 {
  font-family: PoppinsBold;
  font-size: 86px;
  font-weight: 700;
  line-height: 82px;
  text-align: left;
  color: #fff;
  margin: 0px !important;
  text-transform: uppercase;
  
}

.main-heading-and-date-align {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
}

.privacy__top {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
}

.privacy__top__inner {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.privacy__top__inner__text1 {
  font-family: PoppinsBold !important;
  font-size: 86px;
  font-weight: 700;
  line-height: 82px;
  text-align: center;
  color: var(--Black, #323232);
  margin: 0px !important;
}

.inner__text {
  display: flex;
  flex-direction: column;
}

.inner__text__1 {
  font-family: Poppins;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: center;
  color: var(--Black, #323232);
  margin: 0px !important;
}

.inner__text__1_in {
  font-family: PoppinsRegular;
  font-size: 13px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: center;
  color: #323232;
}

.privacy__top__inner_text3 {
  font-family: Poppins !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #323232;
}

.privacy__top__inner_text3_in {
  font-family: Poppins !important;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #323232;
}

.privacy__section_2 {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-left: 244px;
  padding-right: 243px;
  padding-bottom: 141px;
}

.privacy__section_2__toptext {
  font-family: Poppins;
  font-size: 41.44px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  color: #139ed5;
}

.privacy__section_2_d1 {
  display: flex;
  flex-direction: column;
}

.privacy__section_2_d1_text1 {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: #323232;
  margin-bottom: 16px !important;
}

.innnnnnnnnnnner {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #323232;
}

.etc {
  margin-bottom: 16px !important;
}

@media (max-width: 768px) {


  .privacy__top__inner__text1 {
    font-size: 48px;
    line-height: 45px;
  }

  .privacy__section_2__toptext {
    font-size: 30px;
  }

  .inner__text__1,
  .inner__text__1_in {
    font-size: 12px;
    line-height: 14px;
  }

  .privacy__top {
    gap: 50px;
  }

  .privacy__top__inner {
    gap: 30px;
  }

  .privacy__top__inner_text3,
  .innnnnnnnnnnner {
    font-size: 14px;
  }

  .terms-text-heading h1 {
    font-size: 20px;
    line-height: 28px;
  }

  .privacy__top__inner_text3_in {
    font-size: 12px;
    line-height: 16px;
  }

  .privacy__section_2_d1_text1 {
    font-size: 18px;
  }
}

@media (max-width: 480px) {


  .privacy__top__inner__text1 {
    font-size: 36px;
    line-height: 35px;
  }

  .privacy__section_2__toptext {
    font-size: 26px;
  }

  .inner__text__1,
  .inner__text__1_in {
    font-size: 10px;
    line-height: 14px;
  }

  .privacy__top {
    gap: 40px;
  }

  .privacy__top__inner {
    gap: 20px;
  }

  .privacy__top__inner_text3,
  .innnnnnnnnnnner {
    font-size: 12px;
  }

  .terms-text-heading h1 {
    font-size: 20px;
    line-height: 28px;
  }

  .privacy__top__inner_text3_in {
    font-size: 10px;
    line-height: 16px;
  }

  .privacy__section_2_d1_text1 {
    font-size: 18px;
  }
}

.privacy-Top1-inner {
  display: none;
}

@media (max-width: 1200px) {
  .privacy__section_2 {
    padding-left: 200px !important;
    padding-right: 200px !important;
    padding-bottom: 130px;
  }

  .privacy-main____top {
    gap: 180px !important;
  }

  .privacy-top-main {
    padding: 30px 34px 46px 35px;
  }

}

@media (max-width: 1000px) {
  .privacy__section_2 {
    padding-left: 160px !important;
    padding-right: 160px !important;
    padding-bottom: 120px;
  }

  .privacy-top-main {
    background-color: #323232;
  }

  .privacy-main____top {
    gap: 160px !important;
  }

  .effective-date-paragraph {
    display: none;
  }

  .effective-div-for-small-screen {
    display: flex;
    margin-top: 45px;
  }

  .effective-paragraphy-style-small {
    font-family: Poppins;
font-size: 13px;
font-weight: 400;
line-height: 19.5px;
text-align: right;



    color: #fff;
    margin: 0px !important;
  }
  .effective-date-paragraph-span-small{
    color: #fff;
    margin: 0px !important;
    font-family: Poppins;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    text-align: right;


  }
}

@media (max-width: 850px) {
  .privacy__section_2 {
    padding-left: 140px !important;
    padding-right: 140px !important;
    padding-bottom: 110px;
  }

  .privacy-main____top {
    gap: 140px !important;
  }



}

@media (max-width: 700px) {
  .privacy__section_2 {
    padding-left: 110px !important;
    padding-right: 110px !important;
    padding-bottom: 100px;
  }

  .privacy-main____top {
    gap: 120px !important;
  }
}

@media (max-width: 600px) {
  .privacy__section_2 {
    padding-left: 90px !important;
    padding-right: 90px !important;
    padding-bottom: 100px;
  }

  .privacy-main____top {
    gap: 100px !important;
  }
}

@media (max-width: 550px) {
  .privacy__section_2 {
    padding-left: 70px !important;
    padding-right: 70px !important;
    padding-bottom: 80px;
  }

  .privacy-main____top {
    gap: 80px !important;
  }
}

@media (max-width: 460px) {
  .privacy__section_2 {
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-bottom: 60px;
  }

  .privacy-main____top {
    gap: 60px !important;
  }
}

@media (max-width: 400px) {
  .privacy__section_2 {
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-bottom: 40px;
  }

  .privacy-main____top {
    gap: 40px !important;
  }
}

@media (max-width: 350px) {
  .privacy__section_2 {
    padding-left: 20px !important;
    padding-right: 20px !important;
    padding-bottom: 20px;
  }

  .privacy-main____top {
    gap: 20px !important;
  }
}


/* @media (max-width: 1160px) {
  .paragraph-of-main-text {
    padding-top: 30px;
    padding-left: 323px;
    padding-bottom: 32px;
  }

  .privacy-top-heading {
    padding-bottom: 160px;
  }
}  */

@media (max-width: 1000px) {
  .paragraph-of-main-text {
    text-align: left;
    padding-left: 0px;
    padding-top: 22px;
    padding-top: 30px;
    padding-bottom: 32px;
  }

 
  .privacy-Top1-inner {
    display: flex;
    position: absolute;
    bottom: 0px;
    /* left: 100px; */
    z-index: 2;
  }

  .privacy-top-heading {
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 160px;
  }
}

@media (max-width: 700px) {
  .Privacy__main-right {
    padding-left: 60px;
    padding-right: 60px;
  }
}

@media (max-width: 600px) {
  .Privacy__main-right {
    padding-left: 40px;
    padding-right: 40px;
  }
}


@media (max-width: 550px) {
  .Privacy__main-right {
    padding-left: 41px;
    padding-right: 44px;
    padding-top: 48px;

  }
  .privacy-top-heading {
    padding-top: 21.86px !important;
  }
  .effective-div-for-small-screen{
    margin-top: 17px
  }
  .privacy-top-main {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 46px;
  }
  .effective-paragraphy-style-small{
    
font-size: 9px;
font-weight: 400;
line-height: 13.5px;
text-align: left;

  }
  .effective-date-paragraph-span-small{
  
font-size: 9px;
font-weight: 600;
line-height: 13.5px;
text-align: left;

  }

  .evox-text-main-heading{
   
font-size: 65.4px !important;
font-weight: 700 !important;
line-height: 57.03px !important;


  }
  .evox-text-main-heading2{
    font-size: 65.4px !important;
    font-weight: 700 !important;
    line-height: 57.03px !important;
    padding-top: 2px !important;
  }
  .paragraph-of-main-text{
   
font-size: 10.27px !important;
font-weight: 400 !important;
line-height: 15.4px !important;
text-align: left !important;

  }
  .website-evox-bold-text{
    font-size: 10.27px !important;
    font-weight: 400 !important;
    line-height: 15.4px !important;
    text-align: left !important;
  }

  .effective-div-for-small-screen{
    display: flex;
    align-items: center;
   
  }
  .paragraph-of-main-text{
    padding-top: 39px !important;
    padding-bottom: 55px !important;
  }
  .privacy-top-heading {
    padding-left: 25px;
    padding-right: 18px;
  }
}

@media (max-width: 1080px) {
  .paragraph-of-main-text{
  padding-left: 342px;
  padding-top: 40px;}
}

@media (max-width: 1120px) {
  .paragraph-of-main-text{
  padding-left: 0px;
  padding-top: 20px;
  padding-bottom: 60px;
}
}


@media (max-width: 429px) {
  .Privacy__main-right{
    padding-left: 30px;
        padding-right: 30px;
        padding-top: 48px;  
  }
}
@media (max-width: 410px) {
  .evox-text-main-heading {
    font-size: 55.4px !important;
    font-weight: 700 !important;
    line-height: 50.03px !important;
}
.evox-text-main-heading2{
  font-size: 55.4px !important;
    font-weight: 700 !important;
    line-height: 50.03px !important;
}
.effective-paragraphy-style-small{
  font-size: 10px;
        font-weight: 400;
       
}
.effective-date-paragraph-span-small{
  font-size: 10px;
  font-weight: 400;
 
}
}
@media (max-width: 340px) {
  .evox-text-main-heading {
    font-size: 48.4px !important;
    font-weight: 700 !important;
    line-height: 45.03px !important;
}
.evox-text-main-heading2{
  font-size: 48.4px !important;
  font-weight: 700 !important;
  line-height: 45.03px !important;
}
.effective-paragraphy-style-small{
  font-size: 9px !important;
        font-weight: 400 !important;
        line-height: 12.5px !important;
}
.effective-date-paragraph-span-small{
  font-size: 9px !important;
  font-weight: 400 !important;
  line-height: 12.5px !important;
}
.paragraph-of-main-text{
  font-size: 9px !important;
  font-weight: 400 !important;
  line-height: 13.4px !important;
  text-align: left !important;

}
.website-evox-bold-text{
  font-size: 9px !important;
  font-weight: 400 !important;
  line-height: 13.4px !important;
  text-align: left !important;
}
.paragraph-of-main-text{
  padding-bottom: 30px;
}
.effective-div-for-small-screen{
  margin-top: 30px;
}
.privacy-top-heading{
  padding-top: 40px;
}
}