.PrivatePresale_Container {
  background: #f1f1f1;
  max-width: 648px;
  border-radius: 50px;
  padding: 80px 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.PrivatePresale_HText {
  font-family: "PoppinsBold";
  font-size: 36px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0.01em;
  text-align: center;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
}
.Privatepresale_input_Text {
  padding: 13px 24px;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  background: none;
  border: 1px solid #cdcdcd;
  text-decoration-skip-ink: none;
  border-radius: 8px;
  width: 100%;
}
.Privatepresale_input_Text:focus {
  outline: none;
}
.PrivatePresale_Btn {
  background: var(--Evox-Blue, #139ed5);
  padding: 13px 0px;
  width: 100%;
  border-radius: 8px;

  font-family: "Poppins";
  font-size: 18px;
  font-weight: 700;
  line-height: 25.2px;
  text-align: center;
  color: #eff3fa;
  border: none;

  text-decoration-skip-ink: none;
}
.PrivatePresale_LOgoAnd_Text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
}
.PrivatePresale_InputTypeAnd-text_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;
}
.Private_Presale_OverAll_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
}
.PrivatePresale_BOx {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}
.Privatpresale_enter_code_text {
  font-family: "Urbanist-Medium";
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  color: #000000;
  text-align: center;
}
@media (max-width: 768px) {
  .PrivatePresale_Container {
    border-radius: unset;
    max-width: unset;
  }
  .PrivatePresale_Container {
    height: 100%;
  }
  .PrivatePresale_Container {
    padding: 40px 40px;
  }
}
@media (max-width: 500px) {
  .PrivatePresale_HText {
    font-size: 34px;
  }
  .Privatpresale_enter_code_text {
    font-size: 14px;
  }
  .PrivatePresale_LOgoAnd_Text {
    gap: 30px;
  }
  .Private_Presale_OverAll_Container {
    gap: 25px;
  }
  .PrivatePresale_InputTypeAnd-text_container {
    gap: 15px;
  }
}
