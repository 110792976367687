.roadmap-container {
  background-image: url("/public/Images/Home/roadmap\ 1.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 120vh;
  /* height: 1319px; */
  /* position: relative; */
}
.road__main {
  padding-left: 61px;
  padding-right: 61px;
}
.road__main-head {
  padding-left: 180px;
  padding-right: 180px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.road__main-head h1 {
  font-family: PoppinsBold;
  font-size: 41.44px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  color: #139ed5;
}
.road__main-head p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #323232;
}
.roadmap-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 30px;
  /* flex-wrap: wrap; */
  padding-left: 82px;
  padding-right: 82px;
  padding-top: 88px;
  /* padding-bottom: 245px; */
}
.roadmap-container-body {
  display: flex;
  flex-direction: column;
  gap: 164px;
}
.roadmap-container-body1 {
  display: flex;
  flex-direction: column;
  gap: 164px;
  padding-top: 120px;
}

.roadmap-card {
  position: relative;
  max-width: 518px;
  /* border: 1px solid transparent;  */
  border-radius: 19px;
  background: #ffffff1a;
  overflow: hidden;
  padding-left: 49px;
  padding-right: 35px;
  padding-bottom: 35px;
  border: 1px solid #e1e1e1;
  color: #323232;
  cursor: pointer;
}

.roadmap-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: inherit;
  backdrop-filter: blur(24px);
  z-index: 1;
}

.roadmap-card > * {
  position: relative; /* Ensure that children of roadmap-card are above the pseudo-element */
  z-index: 2;
}
.roadmap-card h2 {
  font-family: Poppins;
  font-size: 97.04px;
  font-weight: 700;
  line-height: 145.56px;
  text-align: left;
  /* color: #323232; */
}
.roadmap-card p {
  font-family: Poppins !important;
  font-size: 13px;
  font-weight: 700;
  line-height: 19.5px;
  text-align: left;
  /* color: #323232; */
}
.roadmap-card ul {
  padding-left: 1.5rem;
}
.roadmap-card ul li {
  font-family: PoppinsRegular;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  /* color: #323232; */
}
.roadmap-card:hover{
  background:#139ED5B2;
  color: #ffffff;
}

@media (max-width: 1024px) {
  .road__main-head {
    padding: 0px 70px;
  }
  .roadmap-container {
    padding-left: 50px;
    padding-right: 50px;
  }
  .road__main {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.roadmap-container-sm{
  display: none;
}
@media (max-width: 768px) {
  .roadmap-container {
    display: none;
  }
  .roadmap-container-sm{
    background-image: url("/public/Images/Home/roadmap.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 21.41px;
    padding-top: 52px;
    padding-bottom: 113px;
    padding-left: 40px;
    padding-right: 40px;
  }
  .roadmap-container-body {
    gap: 21.41px;
    align-items: center;
  }
  .roadmap-container-body1 {
    gap: 21.41px;
    align-items: center;
    padding-top: 0px;
  }
}
@media (max-width: 600px) {
  .road__main-head {
    padding-left: 45px;
    padding-right: 45px;
  }
  .road__main-head h1 {
    font-size: 36.74px;
    line-height: 39px;
  }
  .road__main-head p {
    font-size: 11.96px;
    line-height: 17.94px;
  }
}
@media (max-width: 500px) {
  .roadmap-container {
    padding-top: 62px;
    padding-bottom: 55px;
  }
  .roadmap-card h2 {
    font-size: 47.3px;
    line-height: 71px;
  }
  .roadmap-card p {
    font-size: 6.34px;
    line-height: 9.5px;
  }
  .roadmap-card ul li {
    font-size: 6.34px;
    line-height: 9.5px;
  }
  .roadmap-card ul {
    padding-left: 0.9rem;
  }
  .roadmap-card {
    padding: 0px 17px 15px 24px;
    max-width: 252.49px;
  }
}
@media (min-width:1480px) {
  .road__main-head h1{
    font-size: 50px ;
  }
  .road__main-head p{
    font-size: 25px ;
    line-height: 30px;
  }
  .roadmap-card{
    max-width: 650px;
  }
  .roadmap-card p{
    font-size: 20px;
  }
  .roadmap-card ul li{
    font-size: 18px;
  }
  .roadmap-container{
    height: 170vh;
  }
}
