.home__main {
  padding: 60px 64px 0px 65px;
  display: flex;
  gap: 38px;
  overflow-x: hidden;
}
.home__main-left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 15%;
}
.home__main-right {
  background-image: url("/public/Images/Home/bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  padding: 46px 34px 0px 75px;
  position: relative;
  border-radius: 50px;
}
.home__main-right-card {
  margin-top: 120px !important;
  padding-bottom: 233px !important;
  position: relative;
}
.home-Top1-1 {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* padding-right: 38px; */
  margin-bottom: 156px !important;
}
.home-Top1-1-btn {
  display: flex;
  padding: 15px 31px;
  gap: 17px;
  align-items: center;
  height: 78px;
  width: 448px;
  border-radius: 60px;
  background-color: #139ed5;
  /* margin-top: 4px !important; */
  position: absolute;
  bottom: 1.5%;
  left: 0;
  border: none;
  transform: translateX(-62%);
}
.home-Top1-1-btn1 {
  display: flex;
  justify-content: space-between;
  padding: 15px 31px;
  gap: 17px;
  align-items: center;
  height: 78px;
  border-radius: 60px;
  background-color: #139ed5;
  /* margin-top: 4px !important; */
  width: 100%;
  border: none;
  position: relative;
  z-index: 3;
}
.home__build {
  position: absolute;
  top: 0 !important;
  right: 0;
  z-index: 2;
}
.button_div {
  display: none;
}
.home-Top1-inner {
  display: none;
}
/* .roadmap-card ul li:hover
{
  color: #ffffff !important;
} */

@media (max-width: 1200px) {
  .home-Top1-1{
    margin-bottom: 0px !important;
  }
  .navnardiv {
    padding: 0px 12px 0px 20px;
  }
  .home__main {
    padding: 30px 34px 0px 35px;
  }
  .home-Top1-1-btn {
    width: 260px;
    gap: 10px;
    transform: translateX(-25%);
    height: 65px;
    z-index: 50;
  }
  .Contact-Top1-1-btn-text {
    font-size: 12px;
  }
  .home__main-right {
    background-color: #323232;
  }
  .home__main-right-card{
    padding-bottom: 198px !important;
  }
}
@media (max-width: 1000px) {
  .home__main {
    background-color: #323232;
  }
  .home__main-left {
    display: none;
  }
}
@media (max-width: 1000px) {
  .buy__main {
    padding-top: 90px !important;
  }
  .home-Top1-1-btn {
    display: none;
  }
  .home__build {
    top: 400px !important;
    right: -15%;
  }
  .button_div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 59px;
    position: relative;
    margin-top: 350px !important;
  }
  .home__main-right-card {
    display: flex;
    margin-top: 26px;
    /* justify-content: center; */
  }
  .home__main-right {
    padding: 46px 0px 0px 0px;
  }
  .home-Top1-inner {
    display: flex;
    position: absolute;
    bottom: 37px;
    z-index: 2;
  }
  .home-Top1-2 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
  .home-Top1-1-text {
    font-family: Poppins !important;
    font-size: 11.17px;
    font-weight: 700;
    line-height: 16.76px;
    text-align: left;
    color: #ffffff;
    padding-bottom: 3.30px;
  }
  .home-Top1-1-text1 {
    font-family: Poppins;
    font-size: 7.89px;
    font-weight: 400;
    line-height: 11.83px;
    text-align: left;
    width: 105px;
    color: #ffffff;
  }
  .home__main-right-card{
    margin-top: 93px !important;
  }
}
@media (max-width: 768px) {
  .home__build {
    width: 412px;
    height: 479.95px;
    right: -10%;
  }
  .button_div {
    margin-top: 73px !important;
  }
  .home__main-right-card{
    padding-bottom: 180px !important;
  }
}
@media (max-width: 600px) {
  .button_div {
    padding-left: 45px;
    padding-right: 45px;
  }
  .home__build {
    right: -15%;
  }
  .home-Top1-1-btn 
  {
    gap:8px
  }
}
@media (max-width: 430px) {
  .home__build {
    right: -20%;
  }
  .home__main-right-card{
    justify-content: center;
  }
}
@media (min-width:1445px) {
  .home__main-right{
    background-image: url('/public/Images/Home/bgBig.svg');
  }
}

.home-Top1-1-1{
  display: flex;
  gap: 3px;
}


/* for svgs */
/* Hide arrows for Chrome, Safari, Edge, and Opera */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows for Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}