.buy__main {
  padding-top: 162px;
  padding-bottom: 119.63px;
  padding-left: 237px;
  padding-right: 237px;
}
.card__main-txt {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 96px;
  padding-left: 7px;
}
.card__main-txt h1 {
  font-family: PoppinsBold;
  font-size: 41.44px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  color: #139ed5;
}
.card__main-txt p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #323232;
}
.buy__main-body {
  display: flex;
  gap: 39.33px;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.card__main-body-txt {
  padding: 7.46px 23.22px;
  background: #323232;
  border-radius: 20px;
  width: 210.67px;
  height: 275.37px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.card__main-body-txt:hover {
  background: #139ed5;
}
.card__main-body-txt h2 {
  font-family: Poppins;
  font-size: 97.04px;
  font-weight: 700;
  line-height: 145.56px;
  text-align: center;
  color: #ffffff;
}
.card__main-body-txt p {
  font-family: Poppins;
  font-size: 14.1px;
  font-weight: 400;
  line-height: 21.15px;
  text-align: center;
  color: #ffffff;
}
.add-token-button {
  width: 100%;
  height: 30px;
  background: #ffffff;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 600;
  line-height: 16.5px;
  text-align: center;
  color: #323232;
  border-radius: 50px;
  border: none;
  margin-top: 14px !important;
}
@media (max-width: 1024px) {
  .buy__main {
    padding-left: 70px;
    padding-right: 70px;
  }
}
@media (max-width: 600px) {
  .buy__main {
    padding-left: 45px;
    padding-right: 45px;
    padding-top: 47px !important;
    padding-bottom: 80.92px;
  }
  .card__main-txt {
    padding-bottom: 56.44px;
    padding-left: 2px;
  }
  .card__main-txt h1 {
    font-size: 36.74px;
  }
  .card__main-txt p {
    font-size: 11.96px;
  }
  .buy__main-body {
    gap: 26.3px;
    width: 100%;
  }
  .card__main-body-txt {
    width: 151.85px;
    height: 199.28px;
    padding: 5.38px 16.74px;
  }
  .card__main-body-txt h2 {
    font-size: 69.95px;
    line-height: 80px;
    padding-top: 10px;
  }
  .card__main-body-txt p {
    font-size: 10.16px;
    line-height: 15.24px;
  }
}
@media (max-width: 420px) {
  .buy__main-body {
    gap: 15.3px;
    width: 100%;
  }
  .card__main-body-txt {
    width: 47%;
    height: 235.28px;
    padding: 5.38px 16.74px;
  }
  .card__main-body-txt h2 {
    font-size: 69.95px;
    line-height: 104.92px;
  }
  .card__main-body-txt p {
    font-size: 10.16px;
    line-height: 15.24px;
  }
  .add-token-button {
    font-size: 9px;
    height: 25px;
  }
}
@media (max-width: 346px) {
  .card__main-body-txt {
    /* width: 100%; */
    height: 210.28px;
    padding: 5.38px 16.74px;
  }
  .buy__main {
    padding-left: 0px;
    padding-right: 0px;
  }
  .card__main-txt {
    padding-left: 45px;
    padding-right: 45px;
  }
  .buy__main-body {
    padding-left: 15px;
    padding-right: 15px;
  }
  .card__main-body-txt {
    width: 47%;
    height: 240.28px;
    padding: 5.38px 16.74px;
  }
}

@media (min-width: 1480px) {
  .buy__main-body {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns on large screens */
    gap: 39.33px;
    justify-content: center;
  }
  .card__main-txt h1 {
    font-size: 50px;
  }
  .card__main-txt p {
    font-size: 25px;
    line-height: 30px;
  }
  .card__main-body-txt {
    width: 250px;
    height: 310px;
  }
  .card__main-body-txt p {
    font-size: 18px;
    line-height: unset;
  }
}
