.terms-main____top {
  display: flex;
  flex-direction: column;
  padding: 49px 243px 233px 244px;
}

.terms-main {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-items: center;
}
.terms__main-right {
  background-image: url("/public//Images/Terms/bgterm.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  width: 100%;
  padding: 46px 75px 0px 79px;
  position: relative;
  border-radius: 50px;
  margin-bottom: 162px;
}
.terms-Top1-1{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.terms-top-heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 53px;
  padding-top: 74px;
  padding-bottom: 98px;
}

.terms-top-heading h2 {
  font-family: PoppinsBold;
  font-size: 86px;
  font-weight: 700;
  line-height: 80px;
  text-align: left;
  color: #FFFFFF;
  margin: 0px !important;
  text-transform: uppercase;
}

.terms-top-heading p {
  font-family: Poppins !important;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: #FFFFFF;

  margin: 0px !important;
  margin-left: 247px !important;
}
.Terms__textsection {
  display: flex;
  flex-direction: column;
  gap: 60px;
 
}

.terms-text-heading {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.terms-text-heading h1 {
  font-family: Poppins;
  font-size: 41.44px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
  color: #139ed5;
  margin: 0px !important;
}
.terms-text-heading p {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: var(--Black, #323232);
  margin: 0px !important;
}
.refund-span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--Black, #323232);
  margin: 0px !important;
}
.separate-text {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.Defi-span {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: var(--Black, #323232);
  margin: 0px !important;
}

@media (max-width:1000px) {
  .terms__main-right{
    margin-bottom: 0px !important;
  }
}

/* For screens between 900px and 1200px */
@media (min-width: 900px) and (max-width: 1200px) {
  .terms-main____top {
    padding: 50px 100px 100px 100px;
  }

  .terms-main {
    gap: 60px;
  }
  
  .terms-top-heading h2 {
    font-size: 63px;
  }
  .terms-top-heading p {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  

  .terms-text-heading h1 {
    font-size: 30px;
    line-height: 35px;
  }

  .terms-text-heading p {
    font-size: 14px;
    line-height: 22px;
  }

  .separate-text {
    gap: 20px;
  }

  .navnardiv {
    padding-bottom: 131px;
  }
}

/* For screens between 700px and 900px */
@media (min-width: 700px) and (max-width: 900px) {
  /* .terms-main____top {
    padding: 40px 60px 80px 60px;
  } */
  .navnardiv {
    /* padding: 0px 12px 0px 20px; */
    padding-left: 0px;
  
}
  .terms-top-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 53px;
    padding-top: 74px;
    padding-left: 25px;
    padding-bottom: 0px !important;
  }

  .terms-top-heading h2 {
    font-size: 57px;
    line-height: 65px;
    margin-top: 20px !important;
  }

  .terms-top-heading p {
    font-size: 10px;
    line-height: 16px;
    padding-left: 10px;
   
    margin-left: 0px !important;
    
    padding-left: 0px !important;
  }

  .terms-text-heading h1 {
    font-size: 24px;
    line-height: 30px;
  }

  .terms-text-heading p {
    font-size: 12px;
    line-height: 18px;
  }

  .navnardiv {
    padding-bottom: 217px;
  }
  .Terms__textsection {
    display: flex;
    flex-direction: column;
    gap: 60px;
   
}
}

/* For screens between 0px and 700px */
@media (max-width: 700px) {
  
  .terms-main____top {
    padding: 30px 25px 60px 25px;
  }
  .terms__main-right {
    padding: 0px !important;
  }
  /* .terms-top-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 25px;
    gap: 53px;
   
    padding-top: 74px;
    padding-bottom: 0px !important;
  } */
  .terms-top-heading{
    margin-bottom: 140px !important;
  }
  .terms-top-heading p {
    font-size: 9px;
    line-height: 14px;
    padding-left: 0px !important;
    margin-left: 0px !important;
  }

  .terms-text-heading h1 {
    font-size: 20px;
    line-height: 28px;
  }

  .terms-text-heading p {
    font-size: 10px;
    line-height: 16px;
  }

 
  .Terms__textsection {
    display: flex;
    flex-direction: column;
    gap: 60px;
    
}
}

/* For screens below 400px */
@media (max-width: 400px) {
  .terms-main____top {
    padding: 20px 15px 50px 15px;
  }
  .home__main {
    padding: 48px 25px 0px 25px;
}
  .terms__main-right {
    padding: 0px !important;
  }
  .terms-top-heading h2 {
    font-size: 20px;
    line-height: 30px;
    margin-top: 10px !important;
  }
  .terms-top-heading{
    margin-bottom: 0px !important;
  }
  .terms-top-heading p {
    font-size: 8px;
    line-height: 12px;
  }

  .terms-text-heading h1 {
    font-size: 18px;
    line-height: 24px;
  }
 
  .terms-text-heading p {
    font-size: 9px;
    line-height: 14px;
  }
  .Terms__textsection {
    display: flex;
    flex-direction: column;
    gap: 60px;
   
}
    .navnardiv {
        padding: 0px 12px 122px 20px;
    }
}

/* Text alignment for "Please be aware..." from 0px to 1000px width */
@media (min-width: 1000px)and (max-width: 1200px) {
  .terms-top-heading p {
    padding-left: 0px !important;
    margin-left: 0px !important;
  }
  
}

@media (min-width: 1200px) and (max-width: 1250px) {
 
  .terms-top-heading h2{
    font-size: 80px;
  }
  
}

@media (max-width: 1200px) {
  .navnardiv {
      /* padding: 0px 12px 0px 20px; */
      padding-left: 0px;
    
  }
}

@media (min-width: 1441px) and (max-width: 2560px) {
  .terms-top-heading p {
    padding-left:150px !important;
 
  }
}