.custom-modal .modal-dialog {
  border: none;
}
.modal-dialog{
  margin: 0px !important;
  padding: 0px 20px;
}
.modal-dialog-centered {
  max-width: none !important;
  width: 100% !important;
}

.custom-modal .modal-content {
  border-radius: 19px;
  padding-left: 51px;
  padding-right: 52px;
  padding-top: 43px;
  padding-bottom: 30px;
  box-shadow: none;
  border: none;
  width: 371px;
  height: 145px;
}

.modal-text {
  font-family: "Poppins";
  font-size: 12px;
  font-weight: 400;

  text-align: center;
  color: #323232;
  margin-bottom: 17px !important;
}

.text-center {
  padding: 0px !important;
}

.modal-button:hover {
  background-color: #20b66e;
  border-color: #20b66e;
}

@media (max-width: 576px) {
  .custom-modal .modal-dialog {
    max-width: 80%;
  }
}

.Metamask {
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 6px;
  padding-right: 22px;
  border-radius: 60px;
  border: 1px;
  background-color: #fafafa;
  align-items: center;
}

.first {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}

.metatext {
  padding: 0px !important;
  margin: 0px !important;
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
}

.lineswithcontent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 26px !important;
}

.Connecttext {
  font-family: Poppins;
  font-size: 17px;
  font-weight: 700;
  margin: 0px !important;

  color: #139ed5;
}

.modalbody {
  padding: 28px 30px 50px 30px !important;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.customfourth-modal .modal-dialog .modal-content {
  width: 439px !important;
  border: none;
  border-radius: 16px;
}

.btnwithpostion {
  position: absolute;
  top: 4%;
  right: 4%;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}
