.card__main {
  background: #323232;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.card__main-body {
  background: linear-gradient(90.82deg, #333333 0%, #474747 100%);
  justify-content: space-between;
  border: 2px solid #4b4a4a;
  padding: 32px;
  color: #f4f4f4;
  border-radius: 18px;
  display: flex;
  /* align-items: center; */
  width: 100%;
  box-shadow: 0px 4px 4px 0px #00000040;
  position: relative;
  /* z-index: 1; */
}
.updateCard__txt {
  color: #f4f4f4;
  font-family: Poppins;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding-bottom: 5px;
}
.card__main-body-1 {
  display: flex;
  flex-direction: column;
}
.card__main-body-top {
  font-family: Poppins;
  font-size: 15px;
  font-weight: 500;
  line-height: 22.5px;
  text-align: center;
  color: #f4f4f4;
  padding-bottom: 3.57px;
}
.card__main-body-timerTop {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding-bottom: 18.43px;
}
.card__main-body-timer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.card__main-body-timer h2 {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  /* line-height: 86.5px; */
  text-align: center;
}
.card__body-progressbar {
  width: 100%;
  padding: 0px 13px 15px 13px;
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.card__body-progressbar-txt {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.card__body-progressbar-txt p {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: center;
}
.card__body-amt p {
  font-family: Poppins;
  font-size: 11px;
  font-weight: 500;
  line-height: 16.5px;
  text-align: center;
}
.card__body-amt {
  padding-bottom: 8.93px;
}
.card__body-price {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding-bottom: 5.07px;
}
.card__body-price p {
  font-family: Poppins;
  font-size: 11.69px;
  font-weight: 500;
  line-height: 17.54px;
  text-align: center;
}
.card__body-price-border {
  width: 58.45px;
  height: 1.06px;
  background-color: #ffffff;
}
.card__body-btn {
  padding-bottom: 15px;
}
.card__body-btn p {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  text-align: left;
  color: #8d8a8a;
  padding-bottom: 6px;
}
.card__body-btns {
  display: flex;
  gap: 16px;
  align-items: center;
}
.card__body-btns button {
  border-radius: 50px;
  border: 1px solid #ffffff;
  background: transparent;
  width: 50%;
  height: 38px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #f4f4f4;
  font-family: Poppins;
  font-size: 11.69px;
  font-weight: 500;
  line-height: 103%;
  text-align: center;
  justify-content: center;
}
.card__body-box-txt {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card__body-box-txt p {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  color: #ffffff;
}
.card__body-box {
  display: flex;
  gap: 16px;
  justify-content: center;
  padding-bottom: 13px;
}
.card__body-box-container {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.card__body-box-txt span {
  font-family: Poppins;
  font-size: 8px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  color: #8d8a8a;
}
.card__body-box-input {
  position: relative;
}
.card__body-box-input input {
  width: 135px;
  height: 38px;
  border-radius: 50px;
  border: 1px solid #ffffff;
  background: transparent;
  padding-left: 15px;
  color: #f4f4f4;
  font-family: Poppins;
}
.card__body-box-input input::placeholder {
  color: #fff;
  font-family: Poppins;
  /* font-size: 14px; */
}
.card__body-box-input img {
  position: absolute;
  transform: translate(-135%, 40%);
  width: 20px;
  height: 20px;
  /* padding-right: 35px; */
}
.card__main-body-btns {
  display: flex;
  gap: 16px;
}
.card__main-body-btn {
  box-shadow: 3px 4px 4px 0px #139ed52e;
  width: 288px;
  height: 38px;
  background: #139ED5;
  border-radius: 50px;
  border: none;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
}
.card__main-body-btn1 {
  width: 135px;
  height: 38px;
  background: #139ed5;
  box-shadow: 3px 4px 4px 0px #139ed52e;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  border: none;
  border-radius: 50px;
}
.card__main-body-btn2 {
  width: 135px;
  height: 38px;
  background: transparent;
  box-shadow: 3px 4px 4px 0px #139ed52e;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  border: 1px solid #139ed5;
  border-radius: 50px;
}
.card__img {
  position: absolute;
  right: -20px;
  z-index: 99999999;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}
.card__main-right {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
}
.card__main-right p {
  padding-bottom: 14px;
  font-family: Poppins;
  font-size: 13.93px;
  font-weight: 700;
  line-height: 20.89px;
  text-align: center;
  color: #f4f4f4;
}
.card__main-right-box {
  background-color: #565555;
  width: 312px;
  height: 45.5px;
  border-radius: 9.29px;
  display: flex;
  align-items: center;
  padding-left: 20px;
}
.card__main-right-box p {
  padding: 0px;
  font-family: Poppins;
  font-size: 13.93px;
  font-weight: 500;
  line-height: 20.89px;
  text-align: left;
}
.card__main-body-timer p {
  font-family: Poppins;
  font-size: 12.75px;
  font-weight: 500;
  line-height: 19.13px;
  text-align: left;
}
.card-resp {
  -webkit-transition: width 0.3s ease !important;
  will-change: width;
}

@media (max-width: 400px) {
  /* .card-resp {
    width: 100% !important;
  } */
  /* .card__main-body {
    padding: 35px 18px;
  } */
  .home__main {
    padding: 15px 25px 0px 15px;
  }
}
@media (max-width: 768px) {
  .card__main-body {
    padding: 25px 25px;
  }
  .card__main-body-top {
    font-size: 11.35px;
    font-weight: 500;
    line-height: 17.02px;
  }
  .card__main-body-timer h2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 55.44px;
  }
  .card__main-body-timer p {
    font-size: 9.65px;
    font-weight: 500;
    line-height: 14.47px;
  }
  .card__body-progressbar-txt p {
    font-family: Poppins;
    font-size: 8.32px;
    font-weight: 500;
    line-height: 12.48px;
  }
  .card__body-amt p {
    font-family: Poppins;
    font-size: 8.32px;
    font-weight: 500;
    line-height: 12.48px;
    text-align: center;
  }
  .card__body-price-border {
    width: 44px;
  }
  .card__body-price p {
    font-family: Poppins;
    font-size: 8.84px;
    font-weight: 500;
    line-height: 13.27px;
    text-align: center;
  }
  .card__body-btn p {
    font-size: 6.05px;
    font-weight: 500;
    line-height: 9.08px;
  }
  .card__body-btns {
    gap: 13px;
  }
  .card__body-btns button {
    /* width: 50%; */
    height: 28.75px;
    font-size: 8.84px;
    font-weight: 500;
    line-height: 100%;
  }
  .card__body-btns button img {
    width: 15.13px;
    height: 15.13px;
    flex-shrink: 0;
  }
  .card__body-box-input input {
    width: 102.13px;
    height: 28.747px;
    padding-left: 8px;
  }
  .card__body-box-input img {
    width: 15.13px;
    height: 15.13px;
    flex-shrink: 0;
  }
  .card__main-body-btn {
    width: 100%;
  }
  .card__body-box-txt span {
    font-size: 6.05px;
    font-weight: 500;
    line-height: 9.08px;
  }
  .card__body-box-txt p {
    font-size: 6.05px;
    font-weight: 700;
    line-height: 9.08px;
  }
  .card__main-right p {
    font-size: 11.35px;
    font-weight: 700;
    line-height: 17.02px;
  }
  .card__main-right-box {
    width: 254.18px;
  }
  .card__main-right {
    justify-content: center;
    width: 100%;
  }
  .card__main-body-btn1,
  .card__main-body-btn2 {
    width: 102.13px;
    font-size: 7.56px;
    line-height: 11.35px;
  }
  .card__main-body-btns {
    gap: 12px;
  }
  .card__body-box-input input{
    font-size: 14px;
  }
  .card__body-box-input input::placeholder {
    /* color: #fff;
    font-family: Poppins; */
    font-size: 14px;
  }
  .updateCard__txt{
    font-size: 10px;
  }
}
@media (max-width: 374px) {
  .card__main-right-box {
    width: 220.18px;
  }
}
