/* *{
  margin: 0px !important;
} */
.container {
  margin: 0 auto; /* center the container horizontally */
  padding: 20px; /* add some padding to the container */    
}
@media (min-width:2000px) {
  .container-custom{
    max-width: 2000px;  
  }
  .container-f{
    display: flex;
    justify-content: center;
  }
  
}
