.main-container {
  padding: 60px 60px 0px 65px;
  position: relative;
}
.Contact-Main-1 {
  display: flex;
  width: 100%;
  flex-direction: row;

  /* overflow-x: hidden !important; */
  position: relative;
}
.image-homes
{
  display: none;
}
.navbutton-about {
  display: none !important;
  width: 255px !important;
  height: 80px !important;
  border-radius: 60px !important;
  padding: 28px 81px !important;
  background: linear-gradient(
    259.99deg,
    #335ba5 -11%,
    #53c2da 68.34%,
    #5fc7d3 119.07%
  ) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #f4f4f4 !important;
}
.main-image-1 {
  z-index: 10;
}
.main-image-2 {
  z-index: 10;
}
.Contact1-Top1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  padding-bottom: 96px;
}

.Contact-Top3-11-1 {
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: flex-start;
}
.Contact-Top3-11-1-text1 {
  font-family: PoppinsBold !important;
  font-size: 86px;
  font-weight: 700;
  line-height: 75px;
  text-align: left;
  max-width: 288px;
  color: white !important;
  /* width: 28px; */
}
.extrainner {
  display: flex;
  flex: 1;
}
.Contact-Top3-11-1-text2 {
  font-family: PoppinsRegular;
  font-size: 13px;
  font-weight: 400;
  line-height: 19.5px;
  text-align: left;
  color: white !important;
  max-width: 319px;
  /* padding-top: 30px;
  padding-left: 250px; */
}
.globe-container {
  display: flex;
  gap: 12px;
}
.about-Top1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* flex: 1; */
  padding-bottom: 90px;
  width: 15%;
}

.Contact1-Top2 {
  position: relative;
  width: 100%;
  padding: 19px 0px 100px 55px;
  display: flex;
  flex-direction: column;
  flex: 4;
  background-image: url("/public/ContactUS_Images/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  /* Forces the image to fit both width and height */
  background-position: center;
  height: 832px;
}
.Contact1-Top2-1 {
  display: flex;
  flex-direction: row;
  margin-top: 77px !important;
  justify-content: space-between;
}
.Contact1-Top2-11 {
  display: flex;
  flex: 2;
  flex-direction: column;
  gap: 277px;
}
.About-Top1-1-btn {
  display: flex;
  padding: 15px 31px;
  gap: 17px;
  align-items: center;
  height: 78px;
  width: 448px;
  border-radius: 60px;
  background-color: #139ed5;
  margin-top: 67px;
  position: absolute;
  bottom: 1.5%;
  left: 0%;
  border: none;
  transform: translateX(-49%);
  z-index: 50;
}
.About-Top1-1-btn1 {
  display: none;
  padding: 15px 31px;
  gap: 17px;
  align-items: center;
  height: 78px;
  width: 100%;
  border-radius: 60px;
  background-color: #139ed5;
  margin-top: 103px !important;
  border: none;
  position: relative;
  z-index: 50;
  justify-content: space-between;
}
.About-Top1-inner {
  display: none;
  flex-direction: column;
}
.main-image-1 {
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateX(94px);
}
.main-image-2 {
  display: none;
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateX(94px);
}
.Howwork-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.how-heading {
  font-family: PoppinsBold;
  font-size: 238.41px;
  font-weight: 700;
  line-height: 210.51px;
  text-align: left;
}
.Howwork-container-top {
  padding-left: 74px;
  padding-right: 65px;
  margin-top: 103px !important;
}
.Howwork-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.Itwork-container-top {
  display: flex;
  gap: 50px;
  flex-wrap: wrap;
}
.Itwork-container {
  display: flex;
  flex-direction: row;
  gap: 50px;
  align-items: center;
}

.itworks-heading {
  font-family: PoppinsBold !important;
  font-size: 238.41px;
  font-weight: 700;
  line-height: 210.51px;
  text-align: left;
  color: transparent;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: black;
}

.works-heading {
  font-family: PoppinsBold;
  font-size: 238.41px;
  font-weight: 700;
  line-height: 210.51px;
  text-align: left;
  background: linear-gradient(
    259.99deg,
    #335ba5 -11%,
    #53c2da 68.34%,
    #5fc7d3 119.07%
  );
  background-clip: text;
  color: transparent;
}
.image-home {
  position: absolute;
  height: 630px;
  width: 630px;
  right: 10%;
  /* top: 50%; */
  bottom: -16%;
}
.image-homes {
  position: absolute;
  
}
.for-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-left: 30px;
}

.for-text {
  font-family: PoppinsBold !important;
  font-size: 22.44px;
  font-weight: 700;
  line-height: 33.66px;
  text-align: left;
  color: #323232;
}

.for-text-description {
  font-family: "PoppinsRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #323232;
  /* width: 428px; */
}
.for-text-description2 {
  font-family: Poppins;
  font-size: 25.83px;
  font-weight: 700;
  line-height: 33.42px;
  text-align: left;
  color: black;
  margin-top: 16px;
}

.property-container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-left: 7px;
  gap: 6px;
}

.property-heading {
  font-family: PoppinsBold !important;
  font-size: 22.44px;
  font-weight: 700;
  line-height: 33.66px;
  text-align: left;
  color: #323232;
}

.property-description {
  font-family: "PoppinsRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  max-width: 518px;
  position: relative;
  z-index: 2;
  color: #323232;
}
.simplified-text {
  font-family: PoppinsBold !important;
  font-size: 25.83px !important;
  font-weight: 700 !important;
  line-height: 33.42px !important;
  text-align: left !important;
  color:#323232 !important
}
.new-cards-container {
  display: flex;
  position: relative;
  top: 660px;
  right: 750px;
}

.new-card {
  background-color: #333;
  color: #fff;
  display: flex;
  flex-direction: column;
  padding-top: 31px;
  padding-left: 38px;
  border-radius: 10px;
  width: 744px;
  height: 322px;
  margin-bottom: 20px;
}

.new-card-title {
  font-size: 1.25rem;
  margin-bottom: 10px;
}

.new-card-description {
  font-size: 1rem;
}
.cards-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 63px !important;
  padding-left: 122px;
  padding-right: 122px;
  gap: 21px;
  width: 100%;
 
}

.card-column {
  flex: 1;
  max-width: 48%;
  margin: 0 10px;
}

.cardmain-container {
  display: flex;
  flex-direction: column;

  position: relative;
  max-width: 431px;
  gap: 20px;
  background-image: url("/public/Images/AboutUs/card_bg.png");
  background-repeat: no-repeat;
  /* background-color: #139ed5; */
  height: 855px;
  padding: 76px 70px 0px 70px;
  border-radius: 20px;
  background-position: bottom left;
}
.cardmain-container1-top {
  display: flex;
  flex-direction: column;
  gap: 19px;
}
.inner {
  display: flex;

  flex-direction: column;
  gap: 19px;
  padding-top: 168px;
}
.cardmain-container img {
  width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-overlay h2 {
  font-family: Poppins;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
}

.text-overlay p {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}
.cardmain-container1 {
  position: relative;
  width: 100%;
  /* max-width: 400px; */
  /* top:290px; */
  /* margin-bottom: 103px !important; */
  /*  right:400px */
}

.text-overlay1 {
  /* position: absolute;
    top: 20%;
    left: 112%; */
  text-align: center;
  color: #fff;
  padding-top: 52px;
  padding-bottom: 52px;
  padding-left: 38px;
  padding-right: 285px;
  display: flex;
  flex-direction: column;
  gap: 41px;
  background-color: #323232;
  /* height: 322px; */
  border-radius: 20px;
  max-width: 744px;
}

.text-overlay1 h2 {
  font-family: PoppinsBold !important;
  font-size: 41.44px;
  font-weight: 700;
  line-height: 39px;
  text-align: left;
 
}

.text-overlay1 p {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
}

.cardmain-container img {
  object-fit: cover;
  border-radius: 20px;
  width: 431px;
  height: 900px;
}

.text-overlay {
  top: 35%;
  left: 43%;

  text-align: center;
  color: #fff;
  /* padding: 10px; */
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-overlay h2 {
  font-family: PoppinsBold !important;
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
}

.text-overlay p {
  font-family: PoppinsRegular;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
}

.card-main-container2 {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.card-main-container3 {
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.cards-row {
  display: flex;
  gap: 20px;
  /*  padding-top:630px; */
  position: relative;
  /*right:350px*/
}

.cardmain-container2,
.cardmain-container3 {
  background-color: #323232;
  border-radius: 10px;
  padding: 35px 25px;
  max-width: 360px;

  border-radius: 20px;
}

.text-overlay2 {
  top: 35%;
  left: 50%;

  text-align: center;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-overlay2 h2 {
  font-family: PoppinsBold !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
}

.text-overlay2 p {
  font-family: "PoppinsRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  width: 301px;
}

.text-overlay3 {
  top: 35%;
  left: 50%;

  text-align: center;
  color: #fff;
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.text-overlay3 h2 {
  font-family: PoppinsBold !important;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  text-align: left;
}

.text-overlay3 p {
  font-family: "PoppinsRegular";
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  width: 301px;
}
.containercard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #2d2d2d;
  border-radius: 50px;
  padding: 20px;
  height: 450px;
  max-width: 1196px;
  margin: 20px auto;
  color: white;
  position: relative;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding-left: 63px;
  padding-top: 50px;
}

.heading {
  font-family: Poppins;
  font-size: 61.44px;
  font-weight: 700;
  line-height: 65px;
  text-align: left;
}

.subheading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
}

.description {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  width: 850px;
}

.image-container {
  position: relative;
  max-width: 45%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}

.background-image {
  width: 100%;
  border-radius: 15px;
}

.icon-image {
  top: 10px;
  right: 10px;
  width: 40px;
}

.card-container {
  padding-top: 103px;
  display: flex;
  height: 450px;
}

.card {
  background-color: #323232 !important;
  height: 450px !important;
  width: 1196px !important;
  border-radius: 50px !important;
  display: flex !important;
  flex-direction: column !important;
  gap: 23px !important;
  padding-top: 70px !important;
  padding-left: 53px !important;
}

.card-title {
  font-family: Poppins;
  font-size: 61.44px;
  font-weight: 700;
  line-height: 65px;
  color: white !important;
}

.card-description {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 36px;
  color: white !important;
}
.card-description1 {
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: white !important;
  width: 457px;
}
.image-content {
  display: flex;
  justify-content: flex-end;
  padding-left: 620px;
  position: relative;
  top: -370px;
  right: 10px;
}
.icon-image1 {
  height: 55px;
  width: 51px;
}
.icon-card {
  position: relative;
  left: 700px;
  top: -120px;
}
.havequestion-container {
  display: flex;
  flex-direction: column;
  padding-top: 73px;
  gap: 27px;
  margin-bottom: 43px !important;
}
.have-heading {
  font-family: PoppinsBold !important;
  font-size: 61.44px;
  font-weight: 700;
  line-height: 65px;
  text-align: left;
  color: #139ed5;
}
.have-paragraph {
  font-family: PoppinsRegular;
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  text-align: left;
  color: #323232;
}
.pitchcontainer {
  display: flex;
  flex-direction: row;
  gap: 41px;
}

.icon-text-container {
  display: flex;
  gap: 13px;
  align-items: center;
  cursor: pointer;
}
.pitchdescription {
  font-family: "PoppinsRegular";
  font-size: 19px;
  font-weight: 400;
  line-height: 28.5px;
  color: #323232;
}
.icon-image {
  height: 20px;
  width: 20px;
}
.about__card {
  padding: 103px 122px 43px;
}
@media (max-width: 1178px) {
  .Contact1-Top1 {
    display: none;
  }
  .Howwork-container-top {
    padding: 0px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
@media (max-width: 1350px) {
  .main-image-1 {
    transform: translateX(250px);
  }
  .About-Top1-1-btn {
    width: 370px;
    transform: translateX(-41%);
  }
  .Contact1-Top2-11 {
    gap: 228px;
  }
  .text-overlay1 
    {
      padding-right: 38px;
    }
}
@media (max-width: 1300px) {
  .cardmain-container2,
  .cardmain-container3 {
    /* padding-top: 20px;
        padding-left:20px */
    width: 100%;
  }
  .cards-row {
    flex-direction: column;
  }
  .cardmain-container {
    /* padding-left: 25px;
    padding-right: 25px; */
  }
  .cardmain-container2,
  .cardmain-container3 {
    max-width: fit-content;
  }
  .text-overlay2 p,
  .text-overlay3 p,
  .text-overlay1 p,
  .text-overlay1 h2 {
    width: 100%;
    max-width: unset;
  }
  .image-home {
    bottom: -14%;
  }
  .main-image-1 {
    transform: translateX(330px);
  }
}
/* @media (max-width: 1200px) {
    .main-container {
        padding-top: 100px;
        padding-left: 100px;
    }

    .how-heading, .itworks-heading, .works-heading {
        font-size: 200px;
        line-height: 180px;
    }

    .image-home {
        height: 500px;
        width: 500px;
        left: 500px;
    }

    .for-text, .property-heading {
        font-size: 20px;
        line-height: 30px;
    }

    .for-text-description, .property-description {
        font-size: 14px;
        width: 400px;
    }
    .card {
        height: 400px !important;
        width: 1000px !important;
        border-radius: 40px !important;
        padding-top: 60px !important;
        padding-left: 40px !important;
    }

    .card-title {
        font-size: 50px;
        line-height: 60px;
    }

    .card-description {
        font-size: 14px;
        line-height: 30px;
    }

    .card-description1 {
        font-size: 12px;
        line-height: 18px;
        width: 400px;
    }
} */

@media (min-width: 1364px) {
  .for-container1 {
    display: none;
  }
}
@media (max-width: 1364px) {
  .for-container {
    display: none;
  }
}
@media (max-width: 1178px) {
  .cards-container {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 1000px) {
  .works-heading {
    font-size: 150px;
    line-height: 150px;
  }
  .itworks-heading {
    font-size: 150px;
    line-height: 150px;
  }
  .how-heading {
    font-size: 150px;
    line-height: 150px;
  }
  .image-home {
    max-width: 400px;
    bottom: -8%;
    /* top: 45%; */
  }
  .property-description {
    max-width: 350px;
  }
  .about-Top1{
    display: none;
  }
  .property-description {
    /* font-size: 10.27px;
    line-height: 15.41px; */
  }
  .About-Top1-1-btn {
    display: none;
  }
  .About-Top1-1-btn1 {
    display: flex;
  }
  .Contact-Top3-11-1 {
    flex-direction: column;
  }
  .Contact-Main-1 {
    background-color: #323232;
  }
  .navbutton-about {
    display: flex !important;
    width: 100%;
  }
  .main-container {
    padding: 0px;
  }
  .Contact1-Top2 {
    padding: 35px 0px 100px 45px;
  }
  .navb {
    padding: 0px 45px 0px 0px;
  }
  .Contact1-Top2-1 {
    margin-top: 30px !important;
  }
  .Contact1-Top2-11 {
    gap: 30px;
    padding: 0px 45px 0px 0px;
  }
  .button-inner {
    padding: 0px 68px 0px 68px;
  }
  .main-image-1 {
    transform: translateX(550px) translateY(140px);
  }
  .extrainner {
    display: none;
  }
  .About-Top1-inner {
    display: flex;
  }
  .globe-container {
    display: none;
  }
  .image-glob {
    height: 60px;
    width: 60px;
  }
}

@media (max-width: 768px) {
  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .inner {
    padding: 0px 0px;
  }
  .cardmain-container {
    max-width: none;
    width: 100%;
    background-size: 100%;
    padding-bottom: 150px !important;
  }
  .cards-row {
    flex-direction: column;
  }
  .about__card {
    padding: 103px 40px 75px;
  }
  .cardmain-container {
    width: 100%;
    height: unset;
    padding-bottom: 108px;
  }
  .have-heading {
    font-size: 35px;
    line-height: 38px;
  }
}
@media (max-width: 600px) {
  .how-heading {
    font-size: 85.45px;
    line-height: 76.1px;
  }
  .Contact1-Top2{
    padding-left: 20px;
  }
  .itworks-heading {
    font-size: 85.45px;
    line-height: 76.1px;
  }
  .works-heading {
    font-size: 85.45px;
    /* line-height: 76.1px; */
  }
  .for-text {
    font-size: 12px;
    line-height: 18px;
  }
  .for-text-description {
    font-size: 10.27px;
    line-height: 15.41px;
  }
  .Itwork-container-top {
    gap: 10px;
  }
  .property-heading {
    font-size: 12px;
    line-height: 18px;
  }
  .property-description {
    font-size: 10.27px;
    line-height: 15.41px;
    width: 199px;
  }
  .image-home {
    /* top: 22%; */
    bottom: -10%;
    right: 0%;
    width: 260px;
  }
  .cardmain-container {
    padding-left: 58px;
    padding-right: 58px;
  }
  
  .cards-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .main-container{
    padding: 0px;
  }
  .Itwork-container {
    gap: 20px;
  }
  .text-overlay1 {
    gap: 21px;
    padding-right:53px
  }
  .text-overlay2 {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cardmain-container3 {
    padding-left: 53px;
    padding-right: 53px;
  }
  .text-overlay h2,
  .text-overlay1 h2,
  .text-overlay2 h2,
  .text-overlay3 h2 {
    font-family: Poppins;
    font-size: 30.97px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }
  .text-overlay p,
  .text-overlay2 p,
  .text-overlay3 p,
  .text-overlay1 p {
    font-family: Poppins;
    font-size: 11.96px;
    font-weight: 400;
    line-height: 17.94px;
    text-align: left;
  }
  .Contact-Top3-11-1-text2 {
    text-align: left;
  }
  .Contact1-Top2-11 {
    display: flex;
    padding: 0px 60px;
  }
  .main-image-1 {
    transform: translateX(426px) translateY(106px);
    height: 82%;
  }
  .About-Top1-1-btn1 {
    margin-top: 65px !important;
  }
  .have-heading {
    font-size: 35px;
    line-height: 38px;
  }
  .about__card{
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (max-width: 475px) {
  .image-home {
    /* top: 22%; */

    right: -17%;
  }
}
@media (max-width: 430px) {
  /* .main-container,
  .cards-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .image-home {
    width: 200px;
    right:-21%;
    bottom:-11.1%
  }
  .about__card {
    padding: 103px 15px 75px;
  } */
  .main-image-1 {
    transform: translateX(375px) translateY(85px);
    height: 70%;
  }
  .button-inner {
    padding: 15px 15px;
  }
  .Contact-Top3-11-1-text1 {
    font-size: 65.4px;
    line-height: 57px;
  }
  .Contact-Top3-11-1-text2 {
    font-size: 10px;
    line-height: 15.4px;
  }
  .Contact1-Top2-11 {
    padding: 0px 20px 0px 0px;
  }
  .About-Top1-1-btn1 {
    margin-top: 34px !important;
  }
  .have-heading {
    font-size: 36px;
    line-height: 38px;
  }
}
@media (max-width: 430px) {
  .navbutton-about {
    height: 12px !important;
    width: 281px !important;
    font-size: 7px;
  }
  .Howwork-container-top {
    padding-left: 20px;
    padding-right: 20px;
  }
  .for-container1 {
    margin-top: 30px !important;
  }
  .have-paragraph {
    font-size: 12px;
  }
  .pitchdescription {
    font-size: 12px;
  }
}
@media (max-width: 335px) {
  .works-heading {
    font-size: 77px;
    line-height: 80px;
  }
  .cardmain-container1-top {
    width: 100%;
  }
}
@media(min-width: 1445px)
{
 .cardmain-container1-top
 {
  width:100%
 }
 .text-overlay1
 {
  width:100%;
  max-width: unset;
 }
 .cards-row
 {
  width:100%
 }
 .cardmain-container2
 {
  width:50%;
  max-width: unset;
 }
 .cardmain-container3
 {
  width:50%;
  max-width: unset;
 }
 .main-image-1 
 {
 display: none;
 }
 .main-image-2 {
  display:flex;
  position: absolute;
  right: 0px;
  bottom: 0px;
  transform: translateX(431px);
}
 
.Contact-Top3-11-1
{
  padding-right: 80px
}
.how-heading {
 /* font-size: 281px; */
 font-size: clamp(231px, calc(231px + (57 * ((100vw - 1440px) / 480))), 350px);

}
.for-text
{
  font-size: 27px;
  line-height: 46px;
}
.for-text-description
{
  font-size: 22px;
  line-height: 24px;
} 
.simplified-text
{
  font-size: 30px !important;
  line-height: 40px;
}
.itworks-heading
{
  font-size: clamp(238px, calc(238px + (57 * ((100vw - 1440px) / 480))), 360px);
  line-height: 230px;
}
.works-heading
{
  font-size: clamp(238px, calc(238px + (57 * ((100vw - 1440px) / 480))), 380px);
  line-height: 241px;
}
.Itwork-container-top
{
  margin-top:30px !important
}
.property-heading
{
  font-size: clamp(22px, calc(22px + (57 * ((100vw - 1440px) / 480))), 40px);
  line-height: 57px;
}
.property-description
{
  font-size: clamp(16px, calc(16px + (57 * ((100vw - 1440px) / 480))), 22px);
  line-height: 29px;
}
.image-home {
display: none;
}
.image-homes
{
  display: flex;
  height: clamp(630px, calc(630px + (183 * ((100vw - 1440px) / 480))), 813px); 
  width: clamp(630px, calc(630px + (183 * ((100vw - 1440px) / 480))), 813px);
  right:17%;
  bottom:-14%
}
.have-heading
{
  font-size: clamp(61.44px, calc(61px + (57 * ((100vw - 1440px) / 480))), 80px);
  line-height: 80px;
}
.have-paragraph
{
  font-size: 22px;
  line-height: 29px;
}
.pitchdescription
{
  font-size: clamp(16px, calc(16px + (57 * ((100vw - 1440px) / 480))), 26px);
  line-height: 29px;
}
.icon-image
{
  height: clamp(24px, calc(24px + (183 * ((100vw - 1440px) / 480))), 32px); 
  width: clamp(24px, calc(24px + (183 * ((100vw - 1440px) / 480))), 32px);
}

}
@media(max-width:1600px)
{
  .main-image-2 {
   
    transform: translateX(554px);
}
}
@media (min-width:1800px) {
  .image-homes{
    bottom: -27%;
    width: 1000px;
    height: auto;
    right: 13%;
  }
  .text-overlay2 p , .text-overlay3 p{
    width: 450px;
  }
  .text-overlay1{
    padding-left: 50px;
  }
  .cardmain-container2 , .cardmain-container3{
    padding-left: 50px;
    padding-right: 50px;
  }
}
.tele-icon{
  font-size: 40px !important;
}

.about-icon-image {
  fill: black; /* Initial color */
  transition: fill 0.3s ease;
  width: 40px;
  height: 40px;
}

.icon-text-container:hover .about-icon-image {
  fill: #139ed5; /* Change to blue on hover */
}
.icon-text-container:hover .tele-icon,
.icon-text-container:hover .pitchdescription,
.icon-text-container:hover .about-icon-image {
  color: #139ed5; /* Ensure the icon and text color change on hover */
}

/* @media (max-width: 768px) { 
    .main-container {
        padding-top: 80px;
        padding-left: 50px;
    }

    .how-heading, .itworks-heading, .works-heading {
        font-size: 150px;
        line-height: 140px;
    }

    .Howwork-container, .Itwork-container {
        flex-direction: column;
        gap: 15px;
    }

    .image-home {
        height: 400px;
        width: 400px;
        left: 300px;
    }

    .for-text, .property-heading {
        font-size: 18px;
        line-height: 28px;
    }

    .for-text-description, .property-description {
        font-size: 13px;
        width: 350px;
    }
    .card-container {
        padding-top: 80px;
        flex-direction: column;
        align-items: center;
    }

    .card {
        height: 350px !important;
        width: 90% !important;
        border-radius: 30px !important;
        padding-top: 50px !important;
        padding-left: 30px !important;
    }

    .card-title {
        font-size: 40px;
        line-height: 50px;
    }

    .card-description {
        font-size: 13px;
        line-height: 25px;
    }

    .card-description1 {
        font-size: 11px;
        line-height: 16px;
        width: 350px;
    }
    .have-heading
    {
        width: 100%;
        font-size:40px
    }
 
}  */

/* @media (max-width: 480px) {
  .main-container {
    padding: 7px 20px 0px 20px;
  }
  .text-overlay h2 {
    font-family: Poppins;
    font-size: 30.97px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }

  .text-overlay p {
    font-family: Poppins;
    font-size: 11.96px;
    font-weight: 400;
    line-height: 17.94px;
    text-align: left;
  }
  .text-overlay1 h2 {
    font-family: Poppins;
    font-size: 30.97px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }

  .text-overlay1 p {
    font-family: Poppins;
    font-size: 11.96px;
    font-weight: 400;
    line-height: 17.94px;
    text-align: left;
  }
  .text-overlay2 h2 {
    font-family: Poppins;
    font-size: 30.97px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }

  .text-overla2 p {
    font-family: Poppins;
    font-size: 11.96px;
    font-weight: 400;
    line-height: 17.94px;
    text-align: left;
  }
  .text-overlay3 h2 {
    font-family: Poppins;
    font-size: 30.97px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }

  .text-overlay3 p {
    font-family: Poppins;
    font-size: 11.96px;
    font-weight: 400;
    line-height: 17.94px;
    text-align: left;
  }

  .how-heading,
  .itworks-heading,
  .works-heading {
    font-size: 80px;
    line-height: 80px;
  }

  .Howwork-container,
  .Itwork-container {
    flex-direction: column;
    gap: 10px;
  }

  .image-home {
    height: 260px;
    width: 260px;
    left: 100px;
    top: 380px;
  }

  .for-text,
  .property-heading {
    font-size: 16px;
    line-height: 24px;
  }

  .for-text-description,
  .property-description {
    font-size: 12px;
    width: 280px;
  }
  .property-container {
  }
  .for-container {
    padding-left: 0px;
  }
  .card-container {
    padding-top: 60px;
    flex-direction: column;
    align-items: center;
  }

  .card {
    height: 500px !important;
    width: 100% !important;
    border-radius: 20px !important;
    padding-top: 40px !important;
    padding-left: 20px !important;
  }

  .card-title {
    font-size: 30px;
    line-height: 40px;
  }

  .card-description {
    font-size: 12px;
    line-height: 20px;
  }

  .card-description1 {
    font-size: 10px;
    line-height: 14px;
    width: 100%;
  }
  .have-heading {
    width: 100%;
    font-size: 36px;
    line-height: 38px;
  }
  .cards-container {
    display: flex;

    align-items: flex-start;
    margin-top: 63px;
    flex-wrap: wrap;
  }
  .cards-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    right: 30px;
  }
} */
