.token__main {
  padding-top: 35px;
}
.token__main-chart {
  display: flex;
  flex-direction: column;
  gap: 100px;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 126px 50px;
}
.token__main-chart img {
  max-width: 853px;
}
.home__graph img {
  max-width: 1130px;
  width: 100%;
}
.home__graph {
  display: flex;
  justify-content: center;
  padding-bottom: 263px;
  padding-left: 25px;
  padding-right: 25px;
  /* margin-top: 500px !important; */
}
@media (max-width: 1024px) {
  .token__main-chart img {
    width: 100%;
    padding-bottom: 44px;
  }
  .token__main-chart {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 43px 20px;
  }
  .home__graph {
    padding-bottom: 154.8px;
  }
}
@media (max-width: 768px) {
  .Doughnut__res {
    width: 207px !important;
    padding: 18px !important;
  }
  .Doughnut__res-p {
    /* font-family: Poppins; */
    font-size: 6.44px !important;
    font-weight: 500 !important;
    line-height: 9.65px !important;
    text-align: center !important;
  }
  .Doughnut__res-h {
    /* font-family: Poppins; */
    font-size: 22.48px !important;
    font-weight: 700 !important;
    line-height: 33.72px !important;
    text-align: center !important;
  }
}

@media (min-width:1480px) {
  .home__graph img{
    max-width: 1500px;
    padding-left: 50px;
    padding-right: 50px;
  }
}