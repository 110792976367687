.maindiv {
  background-color: #323232;
  padding-top: 140px;
  overflow: hidden;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 122px;
  }
}
.getstarted {
  margin: 0px !important;
  padding: 0px !important;
  font-family: Poppins;
  font-size: 161px;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  display: inline-block;
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: scroll-animation 10s linear infinite;
  /* display: flex;
  gap: 60%; */
}
#scroll-container {
  overflow: hidden;
  width: 100%;
  height: 190px;
  position: relative;
}
@keyframes scroll-animation {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
.content1 {
  text-align: center;
  margin: 0px !important;
  color: #ffffff;
  font-family: Poppins;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 80px;
  padding-bottom: 39px;
  max-width: 804px;
  @media (max-width: 768px) {
    font-size: 16px;
    padding-bottom: 26px;
    padding-top: 53px;
  }
  @media (max-width: 500px) {
    font-size: 10.45px;
    line-height: 15.67px;
    max-width: 300px;
    /* padding: 0 10px; */
  }
}
.footer-button {
  margin-top: 39px;
  display: flex;
  justify-content: center;
}
.join-button {
  /* padding: 30px 80px; */
  background: linear-gradient(
    259.99deg,
    #335ba5 -11%,
    #53c2da 68.34%,
    #5fc7d3 119.07%
  );
  color: #ffffff;
  border: none;
  border-radius: 60px;
  font-size: 14px;
  cursor: pointer;
  font-family: Poppins;
  font-weight: 700;
  width: 255px;
  height: 80px;
  line-height: 21px;
  @media (max-width: 500px) {
    width: 155px;
    height: 48.63px;
    font-size: 8.51px;
  }
}
.evok {
  margin: 0px !important;
  font-family: Poppinsbold !important;
  font-size: 51.97px;
  font-weight: 700;
  line-height: 48.39px;
  color: #ffffff;
  padding-bottom: 32px;
  /* position: relative; */
}
.evokinner {
  margin: 0px !important;
  color: #cfd3d7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  max-width: 324px;
}
.lastfooter {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  margin-top: 161px !important;
  margin-bottom: 100px !important;
  padding: 0px 104px;
  @media (max-width: 768px) {
    justify-content: center;
    padding: 0px 20px;
  }
}
.secondcol {
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* max-width: 324px; */
}
.get {
  margin: 0px !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
}
.address {
  margin: 0px !important;
  color: #cfd3d7;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
}
.circle {
  width: 40px;
  height: 40px;
  background-color: #139ed5;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.insidecircle {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  color: #ffffff;
}
.thirdcol {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 240px;
}
.Join {
  margin: 0px !important;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  color: #ffffff;
  @media (max-width: 500px) {
    font-size: 11.6px;
  }
}
.textBox {
  width: 296px;
  height: 56px;
  border-radius: 60px;
  padding: 15px;
  font-family: PoppinsRegular;
  font-size: 14px;
  font-weight: 400;
  background-color: #ffffff;
  box-sizing: border-box;
  @media (max-width: 500px) {
    width: 200px;
  }
}
.email {
  margin: 0px !important;
  font-family: Poppins;
  font-size: 14px;
  font-weight: 400;
  color: #cfd3d7;
  @media (max-width: 500px) {
    font-size: 10.73px;
  }
}
.fourthcol {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.subbutton {
  padding: 0;
  background-color: #139ed5;
  color: #ffffff;
  border: none;
  border-radius: 60px;
  font-size: 14px;
  cursor: pointer;
  font-family: PoppinsRegular;
  width: 159px;
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  @media (max-width: 500px) {
    width: 100px;
    height: 40px;
    font-size: 10px;
  }
}
.fourcircle {
  display: flex;
  gap: 16px;
  align-items: center;
}
.fourcircle img {
  width: 40px;
  height: 40px;
}
.evokcenter {
  max-width: 324px;
}
.footer__privacy{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 104px;
  margin-bottom: 100px !important;
}
@media (max-width: 500px) {
  .footer__privacy{
  padding: 0px 30px;
  }
  .lastfooter {
    flex-direction: column;
    justify-content: center;
    gap: 50px;
    margin-top: 80px !important;
    margin-bottom: 148px;
    align-items: center;
  }
  .text_footer {
    padding: 0px 69px;
  }
  /* .fourthcol {
    display: none;
  } */
  .evokcenter {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 250px;
  }
  .secondcol {
    max-width: 250px;
  }
  .evokinner {
    text-align: center;
    font-size: 10.73px;
  }
  .get {
    text-align: center;
    font-size: 11.6px;
  }
  .fourcircle {
    display: flex;
    flex-direction: row;
    gap: 16px;
    justify-content: center;
  }
  .evok {
    font-size: 39.85px;
    padding-bottom: 24px;
  }
  .address {
    font-size: 10.15px;
  }
  .fourcircle {
    gap: 13.54px;
  }
  .fourcircle img {
    width: 33.85px;
    height: 33.85px;
  }
  .footer-img {
    width: 17.4px;
    height: 17.4px;
  }
  .getstarted {
    font-size: 54.23px;
    line-height: 81.35px;
  }
  #scroll-container {
    height: 64px;
  }
  .fourthcol {
    align-items: center;
  }
  .email__res {
    align-items: center !important;
  }
}
.icon__FB {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  width: 40px;
  height: 40px;
  background: #139ed5;
}
.icon__text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  letter-spacing: 1px;
  cursor: pointer;
}
.icon__FB:hover {
  background: #04b8ff;
}
@media (max-width: 900px) {
  .text_footer {
    padding: 0px 69px;
  }
}
@media (max-width: 400px) {
  .text_footer {
    padding: 0px 29px;
  }
}

.container-div {
  display: flex;
  align-items: center;
  height: 20vh;
  overflow: hidden;
}

.scroll {
  white-space: nowrap;
  margin: 0 2em;
}

.scroll div {
  display: flex;
  gap: 60em;
}

.scroll p {
  font-size: 161px;
  font-family: PoppinsBold !important;
  color: white;
  /* font-weight: bold; */
  margin-bottom: 0;
  /* line-height: 10px; */
}

.RightToLeft {
  animation: RightToLeft 60s infinite linear;
}

@keyframes RightToLeft {
  from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-50%);
  }
}

.LeftToRight {
  animation: LeftToRight 60s infinite linear;
}

@keyframes LeftToRight {
  from {
    transform: translateX(-50%);
  }
  to {
    transform: translateX(0%);
  }
}

input::placeholder {
  font-family: PoppinsRegular;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  text-align: left;
  color: #77808b;
}
@media (max-width: 600px) {
  .scroll p {
    font-size: 54.23px !important;
    line-height: 81.35px !important;
  }
  .container-div {
    height: 10vh !important;
  }
}
