.css-1tc04iv-MuiToolbar-root {
  padding: 0px !important;
  min-height: auto !important;
}

@media (min-width: 600px) {
  .css-1tc04iv-MuiToolbar-root {
    min-height: auto !important;
  }
}

.mobilenav {
  font-family: Poppins !important;
  font-size: 22px !important;
  font-weight: 400 !important;
  line-height: 33px !important;
  text-align: left !important;
  padding: 0px !important;
  color: #c4c4c4 !important;
  margin: 0px !important;
}

.navoptions {
  font-family: Poppins !important;
  font-size: 14px !important;
  /* font-weight: 400 !important; */
  line-height: 21px !important;
  text-align: left !important;
  padding: 0px !important;
  /* color: #C4C4C4 !important; */
  margin: 0px !important;
  text-transform: unset !important;
}

.logodiv {
  display: none;
}

.menuitemsnavbar {
  gap: 40px !important;
  position: relative;
  z-index: 50;
}

ul.MuiList-root.MuiList-padding.MuiMenu-list.css-99wn1p-MuiList-root-MuiMenu-list {
  display: flex !important;
  /* padding-left: 10px !important;
    padding-bottom: 12px !important; */
  flex-direction: column !important;
  /* gap: 120px !important; */
}
.css-1xomo8h-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  top: 0 !important;
  left: 0 !important;
}
.css-81kwad {
  top: 0 !important;
  left: 0 !important;
}

.navbutton {
  width: 255px !important;
  height: 80px !important;
  border-radius: 60px !important;
  padding: 21px 63px !important;
  background: linear-gradient(
    259.99deg,
    #335ba5 -11%,
    #53c2da 68.34%,
    #5fc7d3 119.07%
  ) !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 700 !important;
  line-height: 21px !important;
  text-align: center !important;
  color: #f4f4f4 !important;
  position: relative;
  z-index: 50;
}

.home__navbutton {
  background: #139ED5 !important;
  position: relative;
  z-index: 100;
  width: 137px !important;
  height: 39px !important;
  border-radius: 50px !important;
  font-family: Poppins !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 21px !important;
  text-align: center;
  color: #ffffff !important;
}

@media (max-width: 1345px) {
  .navbutton {
    width: 223px !important;
    height: 73px !important;
    padding: 21px 47px !important;
  }
}

@media (max-width: 1200px) {
  .navbutton {
    width: 180px !important;
    height: 65px !important;
    padding: 21px 28px !important;
  }

  .menuitemsnavbar {
    gap: 20px !important;
  }
}

@media (max-width: 1000px) {
  .navbutton {
    display: none !important;
  }
  .home__navbutton {
    display: none !important;
  }
  .logodiv {
    display: flex;
  }

  .navmain__ {
    flex-direction: row-reverse;
  }
}
@media (min-width: 600px) {
  .css-7u78ve-MuiButtonBase-root-MuiMenuItem-root {
    min-height: 48px !important;
  }
}
