body, h1, h2, h3, p, ul, ol {
  margin: 0 !important;
}
@font-face {
  font-family: "Poppins";
  src: url("/public/FontFmaily/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsBold";
  src: url("/public/FontFmaily/Poppins-Bold.ttf") format("truetype");
}
@font-face {
  font-family: "PoppinsRegular";
  src: url("/public/FontFmaily/Poppins-Regular.ttf") format("truetype");
}
p{
  font-family: PoppinsRegular !important;
}