.Contact-Main {
    display: flex;
    width: 100%;
    flex-direction: row;
    padding: 60px 54px 100px 65px;
    overflow-x: hidden !important;
  

}

.Contact-Top1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* flex: 1; */
    padding-bottom: 96px;
    width: 15%;
}

.Contact-Top1-1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 38px;
    margin-bottom: 67px !important;
}
.image-container {
    position: relative;
}
.rotate-image {
    height: 50px;
    animation: rotate360 6s linear infinite;
}
.centered-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
@keyframes rotate360 {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.Contact-Top1-1-text {
    font-family: PoppinsBold !important;
    font-size: 17px;
    font-weight: 700;
    line-height: 25.5px;
    text-align: left;
    color: #323232;
    margin: 0px !important;
}

.Contact-Top1-1-text1 {
    font-family: PoppinsRegular;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #323232;
    margin: 0px !important;
}

.Contact-Top1-1-1 {
    display: flex;
    margin-top: 17px !important;
    gap: 4px;
}

.Contact-Top1-1-btn { 
    display: flex;
    padding: 15px 31px;
    gap: 17px;
    align-items: center;
    height: 78px;
    width: 448px;
    border-radius: 60px;
    background-color: #139ED5;
    margin-top: 67px;
    position: absolute;
    bottom: 1.5%;
    left: 0%;
    border: none;
    transform: translateX(-49%);
}

.Contact-Top1-1-btn-text {
    font-family: PoppinsRegular;
    font-size: 14px;
    font-weight: 600;
    line-height: 21px;
    text-align: left;
    margin: 0px !important;
    color: #FFFFFF;

}

.Contact-Top1-inner {
    display: flex;
    flex-direction: column;

}

.Contact-Top2 {
    position: relative;
    width: 100%;
    padding: 19px 0px 100px 75px;
    display: flex;
    flex-direction: column;
    flex: 4;
    background-image: url('/public/ContactUS_Images/bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    /* Forces the image to fit both width and height */
    background-position: center;


}

.Contact-Top1-1-btn1 {
    display: none;
    padding: 15px 31px;
    gap: 17px;
    align-items: center;
    height: 78px;
    width: 100%;
    border-radius: 60px;
    background-color: #139ED5;
    margin-top: 50px !important;
    border: none;
}

.Contact-Top2-1 {
    display: flex;
    flex-direction: row;
    margin-top: 147px !important;
    padding: 0px 145px 0px 14px;
    gap: 60px;
}

.Contact-Top2-11 {
    display: flex;
    flex-direction: column;
    gap: 37px;
    /* flex: 1; */
    max-width: 346px;
}

.Contact-Top2-11-1 {
    display: flex;
    flex-direction: column;
    gap: 25px;
    max-width: 280px;
    /* padding-right: 145px; */
}

.Contact-Top2-11-1-text1 {
    font-family: PoppinsRegular;
    font-size: 39.85px;
    font-weight: 700;
    line-height: 37.1px;
    text-align: left;
    color: #FFFFFF;
    margin: 0px !important;

}

.Contact-Top2-11-1-text2 {
    font-family: PoppinsRegular;
    font-size: 10.73px;
    font-weight: 400;
    line-height: 18.4px;
    text-align: left;
    color: #CFD3D7;
    margin: 0px !important;
}

.Contact-Top2-11-2 {
    /* padding-right: 145px; */
    display: flex;
    flex-direction: column;
}
.Contact-Top2-11-2-div
{
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: space-between;
}

.Contact-Top2-11-2-inner {
    display: flex;
    flex-direction: row;
    gap: 14px;
    align-items: flex-start;

}

.Contact-Top2-11-2-inner-text {
    font-family: PoppinsRegular;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: left;
    color: #CFD3D7;
    margin: 0px !important;
}

.Contact-Top2-12 {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 31px;
}

.Contact-Top2-12-text {
    font-family: PoppinsBold !important;
    font-size: 52.4px;
    font-weight: 700;
    line-height: 57.03px;
    text-align: left;
    color: #FFFFFF;
    letter-spacing: 2px;

}

.Contact-Top2-12-1 {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.Contact-Top2-12-1-input {
    width: 100% !important;
    height: 48.72px;
    padding: 12.86px 17.45px !important;
    border-radius: 9.18px !important;
    border: 0.92px solid #FFFFFF;
    background: transparent !important;
    font-family: PoppinsRegular !important;

    color: #FFFFFF !important;

}

input::placeholder {
    font-family: PoppinsRegular !important;
    font-size: 15.53px;
    font-weight: 400;
    line-height: 23.29px;
    text-align: left;

    color: #979797;
}

input:focus {
    outline: none;
}

.css-9yqzd6-MuiInputBase-root-MuiOutlinedInput-root {
    color: #FFFFFF !important;

    padding: 0px !important;
}

.Contact-Top2-12-1-button {
    width: 100%;
    height: 44px;
    padding: 11px 21px 11px 21px;
    border-radius: 9px;
    background: var(--dark-blue, #139ED5);
    font-family: PoppinsRegular;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    border: none;
    color: #FFFFFF;
    letter-spacing: 0.5px;

}

@media (max-width:1345px) {
    .Contact-Top1-1-btn {
        transform: translateX(-54%);
    }

    .Contact-Top2-1 {
        padding: 0px 130px 0px 14px;
    }
}

@media (max-width:1200px) {
    .Contact-Top2-11-1 {
        /* padding-right: 80px; */
    }
    .Contact-Top1-1-btn {
        transform: translateX(-53%);
        width: 353px;
    }

    .Contact-Top2-1 {
        padding: 0px 80px 0px 14px;
    }
}

@media (max-width:1024px) {
    .Contact-Top2 {

        background-color: #323232;
        padding: 25px 40px 100px 40px;
        border-radius: 60px;
    }

    .Contact-Top2-1 {
        padding: 0px 10px 0px 14px;
    }

    .Contact-Top1-1-btn1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .Contact-Top1-1-btn {
        display: none;
    }
    .Contact-Top2-11-1 {
        padding-right: 70px;
    }
}
@media (max-width:1000px) 
{
    .Contact-Top2-12 {
        gap: 21px;
    }
    .Contact-Top2-12-1 {
        gap:8.42px
    }
    .Contact-Top1 {
        display: none;
    }
    .Contact-Top2 {
        border-radius: 0px;
        padding: 48px 45px 0px 45px;
    }
    .Contact-Main {
        padding: 0px 0px 79px 0px;
    }
    .Contact-Top2-1 {
        padding: 0px;
        margin-top: 88px !important;
        gap: 38.5px;
        flex-direction: column-reverse;
    }
    .Contact-Top2-11-2 {
        align-items: end;
        padding-right: 0.19px;
    }
    .Contact-Top2-11-1 {
        /* padding-right: 160px; */
    }
    .Contact-Top1-1-btn1 {
        font-size: 10px;
        gap: 5px;
        transform: translateY(46px);
    }
    .Contact-Top1-1-btn-text {
        font-size: 14.5px;
    }
    .Contact-Top2-11-1-text2 {
        font-size: 9.34px;
    }
    
}
@media (max-width:530px) 
{
    .Contact-Top2-12-text {
        font-size: 35.94px;
    }
    .Contact-Top2 {
        border-radius: 0px;
        padding: 48px 44px 0px 41px;
    }
    .Contact-Top1-1-btn1 {
        
        gap: 5px;
        height: 54.32px; 
        border-radius: 41px;
        padding: 15px 14px;
        margin-top: 0px !important;
        transform: translateY(36px);
    }
    .Contact-Top1-1-btn-text {
        font-size: 9.5px;
    }
    .imagestyle{
        width: 33px;
        height: 33px;
    }
    .Contact-Top2-11-1-text1 {
        font-size: 34.4px;
    }
    .Contact-Top2-11-1 {
        gap: 21.82px;
    }
    .Contact-Top2-11 {
        gap: 9.18px;
    }
    .Contact-Top2-11-2-inner-text {
        font-size: 10.44px;
    }
    .Contact-Top2-12-1-button {
        font-size: 8.5px;
    }
}
@media (max-width:400px) 
{
    .Contact-Top2-12-text {
        font-size: 25.94px;
    }
    .Contact-Top2 {
     
        padding: 40px 30px 0px 30px;
    }
    .Contact-Top1-1-btn-text {
        font-size: 8.5px;
    }
    .Contact-Top2-11-1-text1 {
        font-size: 25.4px;
    }
    .Contact-Top2-12-1-button {
        font-size: 8.5px;
    }
}
@media (min-width:1480px) {
    .Contact-Top1-1-text1{
        font-size: 15px;
    }
    .Contact-Top1-1-text{
        font-size: 20px;
    }
}